<template>
  <div class="wait-tickets p-2">

    <ol v-if="roundId" class="breadcrumb px-2 py-1 mt-3 mb-0 alert-warning border-warning mb-1">
      <li class="breadcrumb-item">
        <a href="#" @click="toggleRound(null)"><i class="fas fa-angle-left"></i> กลับ</a>
      </li>
      <li class="breadcrumb-item">
        {{roundName}}
      </li>
    </ol>

    <div class="tickets-table position-relative">
      <table class="table table-sm table-bordered table-hover table-info mb-0">
        <thead>
          <tr>
            <th width="20%">หวย</th>
            <th width="15%">งวด</th>
            <th width="10%">ยอด</th>
            <th width="10%">ส่วนลด</th>
            <th width="10%">ถูกรางวัล</th>
            <th width="10%">แพ้/ชนะ</th>
            <th>หมายเหตุ</th>
            <th width="50">ดูโพย</th>
          </tr>
        </thead>
        <tbody v-if="roundId">
          <tr v-for="item in items" :key="item._id">
            <td>{{item.market.marketTitle}}</td>
            <td class="text-center">{{item.round.roundDate}}</td>
            <td class="text-right text-danger">{{ item.summary.amount | amountFormat(2) }}</td>
            <td class="text-right text-success">{{ item.summary.discount | amountFormat(2) }}</td>
            <td class="text-right">0.00</td>
            <td class="text-right text-danger">-{{ item.summary.credit | amountFormat(2) }}</td>
            <td class="text-center">{{ item.remarks }}</td>
            <td class="text-center"><button class="btn btn-outline-info btn-sm" @click="toggleTicket(item._id)"><i class="fas fa-list"></i></button></td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr v-for="group in groups" :key="group[0]._id">
            <td>{{group[0].market.marketTitle}}</td>
            <td class="text-center">
              <a href="#" @click="toggleRound(group[0].round.roundId)">{{group[0].round.roundDate}}</a>
            </td>
            <td class="text-right text-danger">{{ groupAmount[group[0].round.roundId].amount | amountFormat(2) }}</td>
            <td class="text-right text-success">{{ groupAmount[group[0].round.roundId].discount | amountFormat(2) }}</td>
            <td class="text-right">0.00</td>
            <td class="text-right"
              :class="[{
                'text-danger':groupAmount[group[0].round.roundId].winloss < 0
              }, {
                'text-success':groupAmount[group[0].round.roundId].winloss > 0
              }]">{{ groupAmount[group[0].round.roundId].winloss | amountFormat(2) }}</td>
            <td colspan="2" class="text-center"></td>
          </tr>
          <tr v-if="groups.length===0 && !isLoading">
            <td colspan="8" class="text-center">ไม่มีข้อมูล</td>
          </tr>
        </tbody>
        <tfoot v-if="groups.length > 0">
          <tr v-if="roundId">
            <th colspan="2" class="text-center">รวม</th>
            <th class="text-right text-danger">{{ itemSummary.amount | amountFormat(2) }}</th>
            <th class="text-right text-success">{{ itemSummary.discount | amountFormat(2) }}</th>
            <th class="text-right">0.00</th>
            <th class="text-right"
              :class="[{
                'text-danger':itemSummary.credit < 0
              }, {
                'text-success':itemSummary.credit > 0
              }]"
            >{{ itemSummary.credit | amountFormat(2) }}</th>
            <th colspan="2" class="text-right"></th>
          </tr>
          <tr v-else>
            <th colspan="2" class="text-center">รวม</th>
            <th class="text-right text-danger">{{ groupSummary.amount | amountFormat(2) }}</th>
            <th class="text-right text-success">{{ groupSummary.discount | amountFormat(2) }}</th>
            <th class="text-right">0.00</th>
            <th class="text-right"
              :class="[{
                'text-danger':groupSummary.winloss < 0
              }, {
                'text-success':groupSummary.winloss > 0
              }]"
            >{{ groupSummary.winloss | amountFormat(2) }}</th>
            <th colspan="2" class="text-right"></th>
          </tr>
        </tfoot>
      </table>
      <loading
        :active="isLoading"
        :can-cancel="false"
        :is-full-page="false"
        background-color="#EBEDEF"
        :height="60"
        :width="60"
        color="#007bff"
        loader="dots"
      />
    </div>

    <ViewTicketModal :is-show="isShowTicketModal" :ticket-id="viewTicketId" @close="isShowTicketModal=false" />
  </div>
</template>
<script>
import ReportService from '@/services/ReportService'
import Swal from 'sweetalert2'
import moment from '@/helpers/moment'
import _ from 'lodash'
import ViewTicketModal from '@/views/report/components/ViewTicketModal'
import cAlert from '@/helpers/alert'

export default {
  name: 'WaitTickets',
  components: {
    ViewTicketModal
  },
  data() {
    return {
      data: null,
      isLoading: false,
      roundId: null,
      isShowTicketModal: false,
      viewTicketId: null
    }
  },
  computed: {
    roundName() {
      if(!this.roundId)
        return ''

      return `${this.items[0].market.marketTitle} - ${this.items[0].round.roundDate}`
    },
    groups() {
      if(!this.data)
        return []

      const groups = _.groupBy(this.data.items, (item)=>{
        if(!this.roundId)
          return item.round.roundId

        return item._id
      })

      return Object.keys(groups).map((roundId)=>{
        return groups[roundId]
      })
    },
    groupAmount() {
      return this.groups.reduce((groups, tickets)=>{
        groups[tickets[0].round.roundId] = tickets.reduce((summary, ticket)=>{
          summary.amount += ticket.summary.amount
          summary.credit += ticket.summary.credit
          summary.discount += ticket.summary.discount
          summary.winloss += (-ticket.summary.credit)
          return summary
        }, {
          amount: 0,
          credit: 0,
          discount: 0,
          winloss: 0
        })
        return groups
      }, {})
    },
    groupSummary() {
      return Object.keys(this.groupAmount).map((roundId)=>{
        return this.groupAmount[roundId]
      })
      .reduce((sum, group)=>{
        sum.amount += group.amount
        sum.credit += group.credit
        sum.discount += group.discount
        sum.winloss += group.winloss
        return sum
      }, {
        amount: 0,
        credit: 0,
        discount: 0,
        winloss: 0
      })
    },
    items() {
      if(!this.data)
        return []

      return this.data.items.filter((item)=>{
        return item.round.roundId === this.roundId
      })
    },
    itemSummary() {
      return this.items.reduce((summary, item)=>{
        summary.amount += item.summary.amount
        summary.credit -= item.summary.credit
        summary.discount += item.summary.discount
        return summary
      }, {
        amount: 0,
        credit: 0,
        discount: 0
      })
    }
  },
  methods: {
    loadTickets() {
      this.isLoading = true
      ReportService.getTicketWithStatus('wait').then((response)=>{
        if(response.success) {
          this.data = response.data
        }else{
          throw new Error(response?.message)
        }
      })
      .catch((e)=>{
        cAlert({
          ...e,
          title: 'ผิดพลาด!',
          text: e?.message || 'โหลดรายงานไม่สำเร็จ',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
      .finally(()=>{
        this.isLoading = false
      })
    },
    toggleRound(roundId) {
      this.roundId = roundId
    },
    toggleTicket(ticketId) {
      this.isShowTicketModal = true
      this.viewTicketId = ticketId
    }
  },
  mounted() {
    this.loadTickets()
  }
}
</script>
<style lang="scss" scoped>
.wait-tickets {
  .tickets-table {
    background-color: #FFF;
    min-height: 50px;

    table {
      thead {
        tr {
          th {
            font-size: 90%;
            vertical-align: middle;
            text-align: center;
          }
        }
      }
      tbody {
        background-color: #FFF;
        tr {
          td {
            vertical-align: middle;
            font-size: 85%;
          }
        }
      }
    }
  }
}
</style>
